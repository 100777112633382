// src/components/LoginButton.js

import React from 'react';
import './LoginButton.css';

const LoginButton = ({ onClick }) => {
  return (
    <button className="login-button" onClick={onClick}>Login</button>
  );
};

export default LoginButton;
