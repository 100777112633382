// src/components/StartButton.js

import React from 'react';
import './StartButton.css';

const StartButton = ({ onClick , text='Start'}) => {
  return (
    <button className="start-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default StartButton;
