// src/components/LogoutButton.js

import React, { useContext } from 'react';
import UserContext from '../contexts/UserContext';
import './LogoutButton.css'; // Reuse the same CSS file
import logoutImage from '../assets/logout.png'; // Import the logout image

// Function to log sign out data to the server
const logSignOut = async (userId, sessionId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/log-sign-out`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ userId, sessionId }),
        });

        if (response.ok) {
            console.log('Sign out logged successfully');
        } else {
            console.error('Failed to log sign out');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

const LogoutButton = () => {
  const { user, logout } = useContext(UserContext);

  const handleLogout = async () => {
    logSignOut(user.userId, user.sessionId);
    await logout();
    window.location.reload(); // Force a page reload
  };

  return (
    <button className="logout-button" onClick={handleLogout}>
      <img src={logoutImage} alt="Logout" />
    </button>
  );
};

export default LogoutButton;
