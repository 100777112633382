import React, { useState, useEffect, useContext } from 'react';
import './LoginPage.css';
import logo from '../assets/logo.png'; // Add the logo image in src/assets folder
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import UserContext from '../contexts/UserContext';

const LoginPage = ({ onAuthComplete }) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [showLoginSection, setShowLoginSection] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { user, login } = useContext(UserContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
      const loginSectionTimer = setTimeout(() => {
        setShowLoginSection(true);
      }, 2000); // Additional delay to ensure logo animation is complete
      return () => clearTimeout(loginSectionTimer);
    }, 2000); // 2 seconds for logo animation

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (user) {
      onAuthComplete();
    }
  }, [user, onAuthComplete]);

  const handleSignupClick = () => {
        if (isSignup) {
            if (password === confirmPassword && email) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        alert(data.error);
                    } else {
                        login(data.token);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert('An error occurred. Please try again.');
                });
            } else {
                alert('Passwords do not match or email is missing');
            }
        } else {
            setIsSignup(true);
        }
    };

    const handleLoginClick = () => {
        if (email && password) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    login(data.token);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred. Please try again.');
            });
        } else {
            alert('Email or password is missing');
        }
    };

  return (
    <div className="login-page">
      <div className={`logo-section ${isAnimating ? '' : 'to-top'}`}>
        <img src={logo} alt="Tweekey Logo" />
      </div>
      {showLoginSection && (
        <div className="login-section show">
          <h1>Hello, welcome!</h1>
          <input
            type="email"
            placeholder="Email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isSignup && (
            <input
              type="password"
              placeholder="Confirm Password"
              className="login-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
          <div className="button-container">
            {!isSignup && <LoginButton onClick={handleLoginClick} />}
            <SignupButton onClick={handleSignupClick} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
