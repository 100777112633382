// src/components/ResetPasswordButton.js

import React from 'react';
import './ResetPasswordButton.css';

const ResetPasswordButton = ({ onClick }) => {
  return (
    <button className="resetpassword-button" onClick={onClick}>Confirm</button>
  );
};

export default ResetPasswordButton;
