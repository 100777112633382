import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import LoginPage from './components/LoginPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import HomePage from './components/HomePage';
import AdminPage from './components/AdminPage';
import ThanksPage from './components/ThanksPage';
import EnrollPage from './components/EnrollPage';
import DrawPage from './components/DrawPage';
import './App.css';

function App() {
  const { user, logout, updateUser} = useContext(UserContext);
  const [curPage, setCurPage] = useState('login');
  const [prevPage, setPrevPage] = useState('login');
  const [selectedText, setSelectedText] = useState('');
  const [words, setWords] = useState([]);

  useEffect(() => {
    fetch('/words.txt')
      .then(response => response.text())
      .then(text => {
        const wordList = text.split('\n').map(word => word.trim()).filter(word => word);
        setWords(wordList);
      })
      .catch(error => console.error('Error fetching words:', error));
  }, []);

    // Function to log sign out data to the server
    const logSignOut = async (userId, sessionId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/log-sign-out`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ userId, sessionId }),
            });

            if (response.ok) {
                console.log('Sign out logged successfully');
            } else {
                console.error('Failed to log sign out');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLogout = async () => {
        logSignOut(user.userId, user.sessionId);
        logout();
        window.location.reload(); // Force a page reload
    };

    // Function to log sign in data to the server
    const logSignIn = async (userId, sessionId) => {
        console.log('userId:', userId);
        console.log('sessionId:', sessionId);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/log-sign-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ userId, sessionId }),
            });

            if (response.ok) {
                console.log('Sign in logged successfully');
            } else {
                console.error('Failed to log sign in');
                await handleLogout();
            }
        } catch (error) {
            console.error('Error:', error);
            await handleLogout();
        }
    };

  const getRandomWord = () => {
    if (words.length === 0) {
      return '';
    }
    const randomIndex = Math.floor(Math.random() * words.length);
    return words[randomIndex];
  };

  const setPage = (nextPage) => {
    setPrevPage(curPage)
    setCurPage(nextPage)
  }

  const handleAuthComplete = async () => {
    const userAgent = navigator.userAgent;    
    const { sessionId } = await logSession(userAgent);
    await logSignIn(user.userId, sessionId);
    const userSession = { ...user, sessionId: sessionId };
    // After login, update user data with sessionId
    updateUser(userSession);
    if (user['enroll']) {
        setPage('home');
    } else {
        setPage('enroll');
    }
  };

  const handleStart = () => {
    const text = getRandomWord();
    if (text.length) {
      setSelectedText(text);
      setPage('draw');
    }
  };

  const handleEnroll = () => {
    setPage('enroll');
  };

  const handleHome = () => {
    setPage('home');
  };

  const handleAdmin = () => {
    setPage('admin');
  }

  // Function to log events to the server
  const logAction = async (events) => {
    const BATCH_SIZE = 20; // Adjust the batch size as needed
  
    const sendBatch = async (batch) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/log`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(batch),
        });
  
        if (response.ok) {
          console.log('Batch logged successfully');
        } else {
          console.error('Failed to log batch');
          await handleLogout();
        }
      } catch (error) {
        console.error('Error:', error);
        await handleLogout();
      }
    };
  
    for (let i = 0; i < events.length; i += BATCH_SIZE) {
      const batch = events.slice(i, i + BATCH_SIZE);
      await sendBatch(batch);
    }
  };

    // Function to log session data to the server
    const logSession = async (userAgent) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/log-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ userAgent, token }),
            });
    
            if (response.ok) {
                console.log('Session logged successfully');
                const data = await response.json(); // Get the JSON data from the response
                return data
            } else {
                console.error('Failed to log session');
                await handleLogout();
            }
        } catch (error) {
            console.error('Error:', error);
            await handleLogout();
        }
    };

    const _handleDrawComplete = async (events) => {    
        await logAction(events); // Call logAction with the events array

        if (prevPage === 'enroll') {
            setPage('thanks');
        } else {
            setPage('home');
        }
    };

    const [handleDrawComplete, setHandleDrawComplete] = useState(() => _handleDrawComplete);

    // Function to set enroll for the user
    const setEnroll = async (text) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/enroll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ enroll: text }),
        });
    
        if (response.ok) {
            console.log('Enroll set successfully');
        } else {
            console.error('Failed to set enroll');
        }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const _handleEnrollDrawComplete = async (events, text) => {
        await _handleDrawComplete(events);
        // Sent enroll to server
        await setEnroll(text);
        setHandleDrawComplete(()=>_handleDrawComplete);
    }

    const handleImageSelect = (text) => {
        setSelectedText(text);
        setHandleDrawComplete(() => (events) => _handleEnrollDrawComplete(events, text));
        setPage('draw');
      };


  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Main Application Route */}
          <Route path="/" element={
            <>
              {curPage === 'login' && <LoginPage onAuthComplete={handleAuthComplete} />}
              {curPage === 'home' && <HomePage onStart={handleStart} onEnroll={handleEnroll} onAdmin={handleAdmin} />}
              {curPage === 'admin' && <AdminPage onHome={handleHome} />}
              {curPage === 'thanks' && <ThanksPage onHome={handleHome} />}
              {curPage === 'enroll' && <EnrollPage onImageSelect={handleImageSelect} />}
              {curPage === 'draw' && <DrawPage text={selectedText} onDrawComplete={handleDrawComplete} />}
            </>
          } />
          
          {/* Reset Password Route */}
          <Route path="/reset-password" element={<ResetPasswordPage onLogout={handleLogout} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
