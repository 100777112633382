import React, { useState, useEffect, useRef, useContext } from 'react';
import UserContext from '../contexts/UserContext';
import LogoutButton from './LogoutButton';
import './DrawPage.css';
import { nanoid } from 'nanoid'

const DrawPage = ({ text , onDrawComplete, repetitions=3}) => {
  const { user } = useContext(UserContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentRep, setCurrentRep] = useState(0);
  const [wordId, setWordId] = useState('')
  const [charId, setCharId] = useState('')
  const [isDrawing, setIsDrawing] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showCharacter, setShowCharacter] = useState(true);
  const [touchEvents, setTouchEvents] = useState([]);
  const [currentCharacterEvents, setCurrentCharacterEvents] = useState([]);
  const [hideButtons, setHideButtons] = useState(false);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 5;
    contextRef.current = context;

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    // Add non-passive touch event listeners
    canvas.addEventListener('touchstart', startDrawing, { passive: false });
    canvas.addEventListener('touchend', finishDrawing, { passive: false });
    canvas.addEventListener('touchmove', draw, { passive: false });

    // Add UUIDs
    setWordId(nanoid());
    setCharId(nanoid());

    return () => {
      window.removeEventListener('resize', handleResize);

      // Remove non-passive touch event listeners
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchend', finishDrawing);
      canvas.removeEventListener('touchmove', draw);
    };
  }, []);

  const recordEvent = (x, y, z, eventType) => {
    const canvas = canvasRef.current;
    const eventRecord = {
        eventType,
        x,
        y,
        z,
        timestamp: Date.now(),
        height: canvas.height,
        width: canvas.width,
        sessionId: user['sessionId'],
        wordId: wordId,
        charId: charId,
        character: text[currentIndex],
    };
    console.log(eventRecord)

    setCurrentCharacterEvents(prevEvents => [
      ...prevEvents,
      eventRecord
    ]);
  };

  const getOffset = (nativeEvent) => {
    const rect = canvasRef.current.getBoundingClientRect();
    if (nativeEvent.touches) {
      const touch = nativeEvent.touches[0];
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - (rect.top/2), // iOS looks offsetted, TODO: confirm if this is the case with other phones
      };
    }

    return {
      x: nativeEvent.clientX - rect.left,
      y: nativeEvent.clientY - rect.top,
    };
  };

  const startDrawing = (event) => {
    const { x, y } = getOffset(event);
    contextRef.current.beginPath();
    contextRef.current.moveTo(x, y);
    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();
    setIsDrawing(true);
    setShowNextButton(true);
    setShowCharacter(false);
  };

  const finishDrawing = (event) => {
    if (!isDrawing) return;
    contextRef.current.closePath();
    setIsDrawing(false);
    setHideButtons(false); // Show buttons when drawing ends
  };

  const draw = (event) => {
    if (!isDrawing) return; // Disabled to draw 'dots' for the letter `i`

    const { x, y } = getOffset(event);
    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();

    // Check if drawing is near the buttons
    if (buttonsRef.current) {
      const buttonsRect = buttonsRef.current.getBoundingClientRect();
      if (
        x >= buttonsRect.left - 20 &&
        x <= buttonsRect.right + 20 &&
        y >= buttonsRect.top - 20 &&
        y <= buttonsRect.bottom + 20
      ) {
        setHideButtons(true); // Hide buttons when drawing is near
      }
    }
  };

  const handleNextClick = () => {
    setTouchEvents(prevEvents => [...prevEvents, ...currentCharacterEvents]);
    setCurrentCharacterEvents([]);

    if (currentIndex < text.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowNextButton(false);
      setShowCharacter(true);
      setCharId(nanoid());
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    } else if (currentRep < repetitions-1) {
      setCurrentRep(currentRep + 1); // Increment repetition count
      setCurrentIndex(0); // Start over with the first character
      setShowNextButton(false);
      setShowCharacter(true);
      setWordId(nanoid());
      setCharId(nanoid());
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    } else {
      onDrawComplete([...touchEvents, ...currentCharacterEvents]);
    }
  };

  const handleClearClick = () => {
    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setCurrentCharacterEvents([]);
  };

  return (
    <div className="draw-page">
      <LogoutButton/>
      <canvas
        ref={canvasRef}
        className="drawing-canvas"
        // onMouseDown={(e) => {
        //   startDrawing(e);
        //   recordEvent(e.clientX, e.clientY, e.clientZ, 'mousedown');
        // }}
        // onMouseUp={(e) => {
        //   finishDrawing(e);
        //   recordEvent(e.clientX, e.clientY, e.clientZ, 'mouseup');
        // }}
        // onMouseMove={(e) => {
        //   draw(e);
        //   if (isDrawing) {
        //     recordEvent(e.clientX, e.clientY, e.clientZ, 'mousemove');
        //   }
        // }}
        // onTouchStart={(e) => {
        //   startDrawing(e);
        //   const touch = e.touches[0];
        //   recordEvent(touch.clientX, touch.clientY, touch.clientZ, 'touchstart');
        // }}
        // onTouchEnd={(e) => {
        //   finishDrawing(e);
        //   const touch = e.changedTouches[0];
        //   recordEvent(touch.clientX, touch.clientY, touch.clientZ, 'touchend');
        // }}
        // onTouchMove={(e) => {
        //   draw(e);
        //   if (isDrawing) {
        //     const touch = e.touches[0];
        //     recordEvent(touch.clientX, touch.clientY, touch.clientZ, 'touchmove');
        //   }
        // }}
        onPointerDown={(e) => {
            startDrawing(e);
            recordEvent(e.clientX, e.clientY, e.pressure, e.pointerType === 'touch' ? 'touchstart' : 'mousedown');
          }}
        onPointerUp={(e) => {
            finishDrawing(e);
            recordEvent(e.clientX, e.clientY, e.pressure, e.pointerType === 'touch' ? 'touchend' : 'mouseup');
          }}
        onPointerMove={(e) => {
            draw(e);
            if (isDrawing) {
              recordEvent(e.clientX, e.clientY, e.pressure, e.pointerType === 'touch' ? 'touchmove' : 'mousemove');
            }
          }}
      />
      {showCharacter && (
        <div className="text-container">
          <span className="char-span-large">{text[currentIndex].toUpperCase()}</span>
        </div>
      )}
      {showNextButton && (
        <div
          className={`button-container ${hideButtons ? 'hidden' : ''}`}
          id="draw-buttons"
          ref={buttonsRef}
        >
          <button className="clear-button" onClick={handleClearClick}>
            Clear
          </button>
          <button className="next-button" onClick={handleNextClick}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default DrawPage;

