// src/components/HomePage.js

import React, { useState, useEffect, useContext } from 'react';
import StartButton from './StartButton';
import LogoutButton from './LogoutButton';
import './HomePage.css';
import logo from '../assets/logo.png'; // Add the logo image in src/assets folder
import UserContext from '../contexts/UserContext';

const HomePage = ({ onStart, onEnroll, onAdmin }) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [showStartSection, setShowStartSection] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
      const startSectionTimer = setTimeout(() => {
        setShowStartSection(true);
      }, 2000); // Additional delay to ensure logo animation is complete
      return () => clearTimeout(startSectionTimer);
    }, 2000); // 2 seconds for logo animation

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="home-page">
      <LogoutButton/>
      <div className={`logo-section ${isAnimating ? '' : 'to-top'}`}>
        <img src={logo} alt="Tweekey Logo" />
      </div>
      {showStartSection && (
        <div className="start-section show">
          <p>Welcome to <strong>Tweekey</strong>! Click the start button below to generate more data.</p>
          <StartButton onClick={onStart} />
          <p>If you wish to select a new image and re-enroll, click the enroll button below.</p>
          <StartButton onClick={onEnroll} text='Enroll' />
          {user && user['role'] === 'admin' && (
            <div>
            <br></br>
            <br></br>
            <StartButton onClick={onAdmin} text='Admin' />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePage;
