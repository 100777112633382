// src/components/SignupButton.js

import React from 'react';
import './SignupButton.css';

const SignupButton = ({ onClick }) => {
  return (
    <button className="signup-button" onClick={onClick}>Signup</button>
  );
};

export default SignupButton;
