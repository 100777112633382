// src/components/EnrollPage.js

import React, { useState, useEffect } from 'react';
import StartButton from './StartButton';
import LogoutButton from './LogoutButton';
import './EnrollPage.css';
import logo from '../assets/logo.png';
import cowImage from '../assets/enroll/small/cow.png';
import dogImage from '../assets/enroll/small/dog.png';
import keyImage from '../assets/enroll/small/key.png';
import bagImage from '../assets/enroll/small/bag.png';
import foxImage from '../assets/enroll/small/fox.png';
import catImage from '../assets/enroll/small/cat.png';

const EnrollPage = ({ onImageSelect }) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [showStartSection, setShowStartSection] = useState(false);
  const [showEnrollSection, setShowEnrollSection] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
      const startSectionTimer = setTimeout(() => {
        setShowStartSection(true);
      }, 2000);
      return () => clearTimeout(startSectionTimer);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleStartClick = () => {
    setShowStartSection(false);
    setShowEnrollSection(true);
  };

  const images = [
    { src: cowImage, alt: 'COW' },
    { src: dogImage, alt: 'DOG' },
    { src: keyImage, alt: 'KEY' },
    { src: bagImage, alt: 'BAG' },
    { src: foxImage, alt: 'FOX' },
    { src: catImage, alt: 'CAT' }
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setTimeout(() => {
      onImageSelect(image.alt);
    }, 2000);
  };

  const handleClick = () => {
    if (selectedImage) {
      onImageSelect(selectedImage.alt);
    }
  };

  return (
    <div className="enroll-page">
      <LogoutButton/>
      {!showEnrollSection && (
        <div className="enroll-page-intro">
          <div className={`logo-section ${isAnimating ? '' : 'to-top'}`}>
            <img src={logo} alt="Tweekey Logo" />
          </div>
          {showStartSection && (
            <div className="start-section show">
              <p>Welcome to <strong>Tweekey</strong>, let's start to pick your favorite image and enroll your profile</p>
              <StartButton onClick={handleStartClick} />
            </div>
          )}
        </div>
      )}
      {showEnrollSection && (
        <>
          {selectedImage ? (
            <div className="selected-image-container" onClick={handleClick}>
              <img src={selectedImage.src} alt={selectedImage.alt} className="selected-image" />
              <div className="image-text">
                {selectedImage.alt.split('').map((char, index) => (
                  <span key={index} className="char-span">
                    {char}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                {images.slice(0, 2).map((image) => (
                  <img
                    key={image.alt}
                    src={image.src}
                    alt={image.alt}
                    className="enroll-image"
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
              <div className="row">
                {images.slice(2, 4).map((image) => (
                  <img
                    key={image.alt}
                    src={image.src}
                    alt={image.alt}
                    className="enroll-image"
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
              <div className="row">
                {images.slice(4, 6).map((image) => (
                  <img
                    key={image.alt}
                    src={image.src}
                    alt={image.alt}
                    className="enroll-image"
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EnrollPage;
