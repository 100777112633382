import React, { useState, useEffect } from 'react';
import './ResetPasswordPage.css';
import logo from '../assets/logo.png'; // Add the logo image in src/assets folder
import ResetPasswordButton from './ResetPasswordButton';
import { useSearchParams, useNavigate } from 'react-router-dom';


const ResetPasswordPage = ({ onLogout }) => {
    const [token, setToken] = useState(null);
    const [isReset, setIsReset] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Initialize useNavigate
    const [isAnimating, setIsAnimating] = useState(true);
    const [showResetPasswordSection, setShowResetPasswordSection] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
        setToken(tokenFromUrl);
    }
    else {
        (async () => {
            navigate('/'); // Navigate back to the root route
            await onLogout();
        })();
    }

    const timer = setTimeout(() => {
      setIsAnimating(false);
      const resetpasswordSectionTimer = setTimeout(() => {
        setShowResetPasswordSection(true);
      }, 2000); // Additional delay to ensure logo animation is complete
      return () => clearTimeout(resetpasswordSectionTimer);
    }, 2000); // 2 seconds for logo animation

    return () => clearTimeout(timer);
  }, [searchParams, onLogout, navigate]);

  useEffect(() => {
    if (isReset) {
        (async () => {
            navigate('/'); // Navigate back to the root route
            await onLogout();
        })();
    }
  }, [isReset, onLogout, navigate]);


    const handleResetPasswordClick = () => {
        if (password === confirmPassword) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ password }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    setIsReset(true);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred. Please try again.');
            });
        } else {
            alert('Passwords do not match');
        }
    };

  return (
    <div className="resetpassword-page">
      <div className={`logo-section ${isAnimating ? '' : 'to-top'}`}>
        <img src={logo} alt="Tweekey Logo" />
      </div>
      {showResetPasswordSection && (
        <div className="resetpassword-section show">
            <h1>Please, reset your password!</h1>
            <input
                type="password"
                placeholder="Password"
                className="resetpassword-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                type="password"
                placeholder="Confirm Password"
                className="resetpassword-input"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="button-container">
                <ResetPasswordButton onClick={handleResetPasswordClick} />
            </div>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordPage;
