// src/components/AdminPage.js

import React, { useState, useEffect } from 'react';
import StartButton from './StartButton';
import LogoutButton from './LogoutButton';
import './AdminPage.css';
import logo from '../assets/logo.png';


const AdminPage = ({ onHome }) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [showStartSection, setShowStartSection] = useState(false);
  const [users, setUsers] = useState([]); // State to store fetched user data
  const [headers, setHeaders] = useState([]); // State to store dynamic headers
  const [shouldRefetchUsers, setShouldRefetchUsers] = useState(false); // State to track when to refetch users


  useEffect(() => {
    // Function to fetch users
    const getUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                console.log('Users fetched successfully');
                const data = await response.json(); // Get the JSON data from the response
                setUsers(data); // Store the user data in state
      
                // Set headers based on the keys of the first object in the data array
                if (data.length > 0) {
                    setHeaders([...Object.keys(data[0]), 'Actions']); // Add 'Actions' for the last column
                }
            } else {
                console.error('Failed to fetch users');
                onHome(); // Redirect to home if the fetch fails
            }
        } catch (error) {
            console.error('Error:', error);
            onHome(); // Redirect to home if there's an error
        }
    };

    getUsers(); // Call the function to fetch users when the component mounts


    const timer = setTimeout(() => {
      setIsAnimating(false);
      const startSectionTimer = setTimeout(() => {
        setShowStartSection(true);
      }, 2000); // Additional delay to ensure logo animation is complete
      return () => clearTimeout(startSectionTimer);
    }, 2000); // 2 seconds for logo animation

    return () => clearTimeout(timer);
    }, [shouldRefetchUsers]); // Refetch users when shouldRefetchUsers changes

    // Function to handle password reset
    const handleResetPassword = async (userId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-password-url`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ userId, userAgent: navigator.userAgent }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.resetToken) {
                    const resetUrl = `${window.location.href}reset-password?token=${data.resetToken}`
                    console.log(`Password reset link: ${resetUrl}`)
                    alert(`Password reset link: ${resetUrl}`)
                } else {
                    alert('Reset token is not defined')
                }
            } else {
                console.error('Failed to generate reset password URL');
                alert('Failed to generate reset password URL.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while attempting to reset the password.');
        }
    };

    // Function to handle enroll reset
    const handleResetEnroll = async (userId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-enroll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ userId }),
            });

            if (response.ok) {
                console.log('Enroll reset successfully');
                alert('Enroll reset successfully');
                setShouldRefetchUsers(prev => !prev); // Toggle the state to refetch users
            } else {
                console.error('Failed to reset enroll');
                alert('Failed to reset enroll');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while attempting to reset the enroll.');
        }
    }

  return (
    <div className="admin-page">
      <LogoutButton />
      <div className={`logo-section ${isAnimating ? '' : 'to-top'}`}>
        <img src={logo} alt="Tweekey Logo" />
      </div>
      {showStartSection && (
        <div className="start-section show">
          {/* Table showing the users info */}
          <table className="user-table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  {headers.map((header) => (
                    header !== 'Actions' ? (
                      <td key={header}>{user[header]}</td>
                    ) : (
                      <td key="actions">
                        <select onChange={(e) => {
                            if (e.target.value === 'reset-password') {
                                handleResetPassword(user.userId);
                                e.target.selectedIndex = 0; // Reset dropdown to the default state
                            } else if (e.target.value === 'reset-enroll') {
                                handleResetEnroll(user.userId);
                                e.target.selectedIndex = 0; // Reset dropdown to the default state
                            }
                        }}>
                            <option value="">Actions</option> {/* Default option */}
                            <option value="reset-password">Reset Password</option>
                            <option value="reset-enroll">Reset Enroll</option>
                        </select>
                      </td>
                      
                    )
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <StartButton onClick={onHome} text='Home' />
        </div>
      )}
    </div>
  );
};

export default AdminPage;
